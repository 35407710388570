import { graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { CTAButton } from '../components/shared/layout/cta';
import FxLayout from '../components/shared/layout/layout';
import uiService from '../components/shared/ui-service';
import { mergePreviewData } from '../services/merge-preview-data';

export const query = graphql`
  query {
    site {
      siteMetadata {
        name
        tagline
        siteUrl
      }
    }
  }
`;

export default class IndexPage extends React.Component<any, any> {
  uiService = uiService;
  constructor(props: any) {
    super(props);

    this.state = {
      packageRange: [],
      selectedPackage: -1,
      collectionLayout: true,
      previewPageContext: undefined,
    };

    uiService.headerInverted = true;
    this.uiService.headerStickyClass = 'fx-l-header--sticky fx-l-header--dark';
    this.setCollectionLayout = this.setCollectionLayout.bind(this);
  }

  setCollectionLayout() {
    this.setState({ collectionLayout: !this.state.collectionLayout });
  }

  componentDidMount() {
    const IS_BROWSER = typeof window !== 'undefined';
    const staticData = this.props.pageContext;
    if (IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__) {
      this.setState({ previewPageContext: mergePreviewData(staticData, window.__PRISMIC_PREVIEW_DATA__) });
    }
  }

  render() {
    const pageContext = this.state.previewPageContext || this.props.pageContext;
    const seoMeta = pageContext.seoMeta && pageContext.seoMeta.primary;
    const contentPanels = pageContext.contentPanels || [];

    let pageBG;

    if (pageContext.page && pageContext.page.data.background_image) {
      pageBG = {
        backgroundImage: `url(${pageContext.page.data.background_image.url || ''})`,
      };
    }

    return (
      <FxLayout sitemap={pageContext.sitemap}>
        {
          <Helmet>
            <title>{seoMeta && seoMeta.seo_title.text}</title>
            <meta name="keywords" content={seoMeta && seoMeta.seo_keywords.text} />
            <meta name="description" content={seoMeta && seoMeta.seo_description.text} />
            <link
              type="canonical"
              href={`${this.props.data.site.siteMetadata.siteUrl}${pageContext.page && pageContext.page.url}`}
            />
          </Helmet>
        }
        <div
          className="fx-l-instruction"
          style={{
            backgroundImage: pageBG && pageBG.backgroundImage ? pageBG.backgroundImage : '',
          }}
        >
          {contentPanels.map((panel: any) => {
            if (panel.type !== 'collection') {
              return panel.items.map((item: any) => {
                if (item) {
                  const templates = item.content.document && item.content.document.data.templates;
                  const data = item.content.document && item.content.document.data;
                  if (templates) {
                    if (
                      templates.find((template: any) => {
                        return template.template && template.template.uid.includes('grid');
                      })
                    ) {
                      return (
                        <section
                          className="fx-l-section fx-l-grid"
                          key={`${new Date().getMilliseconds()}-${Math.random()}`}
                        >
                          <div className="fx-container">
                            <div className="fx-layout">
                              <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--12 fx-layout-col-laptop--6">
                                {data.title.text}
                              </div>
                            </div>
                          </div>
                        </section>
                      );
                    }
                    if (
                      templates.find((template: any) => {
                        return template.template && template.template.uid === 'fx-l-hero-unit';
                      })
                    ) {
                      return (
                        <div className={`fx-l-hero`} key={`${new Date().getMilliseconds()}-${Math.random()}`}>
                          <div className="fx-l-hero-content">
                            <div className="fx-container">
                              <h1 className="fx-l-hero-title">{data.title.text}</h1>
                              <p className="fx-l-hero-subtitle">{data.body.text}</p>
                              <CTAButton data={data} />
                            </div>
                          </div>
                        </div>
                      );
                    }

                    if (
                      templates.find((template: any) => {
                        return template.template && template.template.uid.includes('fx-l-section--split');
                      })
                    ) {
                      return (
                        <section
                          className={`fx-l-section ${templates
                            .map((template: any) => `${template.template && template.template.uid}`)
                            .join()
                            .replace(',', ' ')}`}
                          key={`${new Date().getMilliseconds()}-${Math.random()}`}
                        >
                          <div className="fx-l-section-content">
                            <div className="fx-container">
                              <div className="fx-layout">
                                <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--12">
                                  {data.images.find((image: any) => image.image.url) &&
                                    data.images.map((image: any) => (
                                      <img
                                        className="fx-l-section-media"
                                        key={`image--${new Date().getMilliseconds()}-${Math.random()}`}
                                        src={image.image.url}
                                        alt={image.image.alt}
                                      />
                                    ))}
                                </div>
                                <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--12">
                                  <h3 className="fx-l-section-title">{data.title.text}</h3>
                                  <p className="fx-l-section-body">{data.body.text}</p>
                                  <CTAButton data={data} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      );
                    } else {
                      let sectionBgImage;

                      if (data.background_image && data.background_image.url) {
                        sectionBgImage = {
                          backgroundImage: `url(${data.background_image && data.background_image.url})`,
                          backgroundSize: 'cover',
                        };
                      }

                      return (
                        <section
                          className={`fx-l-section ${templates
                            .map((template: any) => `${template.template && template.template.uid}`)
                            .join()
                            .replace(',', ' ')}`}
                          key={`${new Date().getMilliseconds()}-${Math.random()}`}
                          style={sectionBgImage}
                        >
                          <div className="fx-l-section-content">
                            <div className="fx-container">
                              {data.images.find((image: any) => image.image.url) &&
                                data.images.map((image: any) => (
                                  <img
                                    className="fx-l-section-media"
                                    key={`image--${new Date().getMilliseconds()}-${Math.random()}`}
                                    src={image.image.url}
                                    alt={image.image.alt}
                                  />
                                ))}
                              <h3 className="fx-l-section-title">{data.title.text}</h3>
                              <p className="fx-l-section-body">{data.body.text}</p>
                              <CTAButton data={data} />
                            </div>
                          </div>
                        </section>
                      );
                    }
                  } else {
                    return null;
                  }
                }
                return null;
              });
            } else {
              return (
                <div
                  className={`fx-l-collection  
                ${
                  panel &&
                  panel.items &&
                  panel.items.slice(0, 1).map((item: any) => {
                    return item.templates
                      ? item.templates.map((template: any) =>
                          template.template && template.template.uid.includes('--secondary')
                            ? ' fx-l-section--secondary'
                            : ''
                        )
                      : item.data.templates.map((template: any) =>
                          template.template && template.template.uid.includes('--secondary')
                            ? ' fx-l-section--secondary'
                            : ''
                        );
                  })
                }
                `}
                >
                  <div className="fx-container">
                    <div className="fx-layout">
                      {panel &&
                        panel.items &&
                        panel.items.map((item: any) => {
                          const templates = item.templates || item.data.templates;
                          return (
                            <div
                              className={`${
                                this.state.collectionLayout
                                  ? 'fx-layout-col-mobile--24 fx-layout-col-tablet--8'
                                  : 'fx-layout-col-mobile--24'
                              }`}
                              key={`${new Date().getMilliseconds()}-${Math.random()}`}
                            >
                              <section
                                className={`fx-l-section fx-l-section--collection fx-l-section--${
                                  templates &&
                                  templates
                                    .map((template: any) => `${template.template && template.template.uid}`)
                                    .join()
                                    .replace(',', ' ')
                                }`}
                                key={`${new Date().getMilliseconds()}-${Math.random()}`}
                              >
                                {item.data.images
                                  ? item.data.images.find((image: any) => image.image.url) &&
                                    item.data.images.map((image: any) => (
                                      <img
                                        className="fx-l-section-media"
                                        key={`image--${new Date().getMilliseconds()}-${Math.random()}`}
                                        src={image.image.url}
                                        alt={image.image.alt}
                                      />
                                    ))
                                  : item.images &&
                                    item.images.find((image: any) => image.image.url) &&
                                    item.images &&
                                    item.images.map((image: any) => (
                                      <img
                                        className="fx-l-section-media"
                                        key={`image--${new Date().getMilliseconds()}-${Math.random()}`}
                                        src={image.image.url}
                                        alt={image.image.alt}
                                      />
                                    ))}
                                <h3 className="fx-l-section-title">{item.data.title && item.data.title.text}</h3>
                                <div
                                  className="fx-l-section-subtitle"
                                  dangerouslySetInnerHTML={{ __html: item.data.subtitle && item.data.subtitle.html }}
                                />
                                <p className="fx-l-section-body">{item.data.body && item.data.body.text}</p>
                                <CTAButton data={item.data} />
                              </section>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </FxLayout>
    );
  }
}
